import { Component, Input, OnInit } from '@angular/core';
import {
  AlertController,
  IonNav,
  MenuController,
  PopoverController,
} from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu-option.page.html',
  styleUrls: ['./menu-option.page.scss'],
})
export class MenuOptionComponent implements OnInit {
  constructor(
    private caseService: CaseService,
    private alertCtrl: AlertController,
    private popoverController: PopoverController,
    private ionNav: IonNav
  ) {}
  @Input() goToPersonalDetail: (myNav) => void;

  ngOnInit() {}

  async confirmLogout() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Logout!',
      message: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Logout',
          handler: () => {
            localStorage.removeItem('remote_token');
            localStorage.removeItem('isConsent');
            localStorage.removeItem('isNotificationShown');
            let from_viewmycase = parseInt(
              localStorage.getItem('from_viewmycase')
            );
            if (from_viewmycase) {
              window.location.href = '/viewmycase';
            } else {
              window.location.href = '/login?dynamic=1';
            }
          },
        },
      ],
    });
    await alert.present();
  }

  goToProfile() {
    this.goToPersonalDetail && this.goToPersonalDetail(this.caseService.ionNav);
    this.popoverController.dismiss();
  }
}
