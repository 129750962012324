import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss'],
})

export class NotificationPopupComponent implements OnInit {
  @Input() message: string = '';
  sanitizedHtml: SafeHtml;

  constructor(private modalController: ModalController, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.message) {
      this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(this.message);
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}



