import { Component, Input, OnInit } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
import { IonNav } from '@ionic/angular';
import { PaymentMethodPage } from '../payment-method/payment-method.page';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-gateways',
  templateUrl: './payment-gateways.page.html',
  styleUrls: ['./payment-gateways.page.scss'],
})
export class PaymentGatewaysPage implements OnInit {
  @Input() caseData;
  @Input() planType;
  @Input() selectedPlanData;
  paymentBtnDisabled = false;
  availablePaymentListArray = [];
  paymentMethod = [];
  defaultPaymentPortal = 'usaepay';
  paymentList: any = {
    mobipaid: {
      text: 'Mobipaid',
    },
    usaepay: {
      text: 'USA ePay',
    },
    govukpay: {
      text: 'GOV.UK Pay',
    },
    sagepay: {
      text: 'Sage Pay',
    },
    payroc: {
      text: 'Pay Roc',
    },
    stripe: {
      text: 'Stripe Pay',
    },
    gocardless: {
      text: 'GoCardless Pay',
    },
    trxServices : {
      text : 'EzPay / TRX Services'
    }
  };
  paymentGateways = [
    {
      title: 'Card payment',
      type: 'card',
      icon: 'card-outline',
      src: null,
    },
    {
      title: 'Ach payment',
      type: 'ach',
      icon: 'card-outline',
      src: 'assets/icon/ach2.svg',
    },
    {
      title: 'Bank payment',
      type: 'bank',
      icon: 'card-outline',
      src: 'assets/icon/bank2.svg',
    },
  ];

  constructor(
    private caseService: CaseService,
    private commonService: CommonService,
    private router: Router,
    private ionNav: IonNav
  ) {}

  ngOnInit() {
    this.checkAvailablePaymentList();
    this.setSelectedPlanData();
  }
  checkAvailablePaymentList() {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      this.availablePaymentListArray = [];
      Object.values(payment_type).forEach((value: any, index) => {
        if (index == 0) {
          this.defaultPaymentPortal = value.key;
        }
        if (this.paymentList[value.key]) {
          this.paymentList[value.key].text =
            value.label || this.paymentList[value.key].text;
          this.paymentList[value.key].value = value.key;
          this.availablePaymentListArray.push(this.paymentList[value.key]);
        }
      });
    }
    if (this.availablePaymentListArray.length == 0) {
      this.paymentBtnDisabled = true;
    }
  }

  handleGatewayClick(selectedGateway) {
    if (selectedGateway) {
      this.caseService.setPaymentData({ selectedGateway });
      this.ionNav.push(PaymentMethodPage, {
        caseData: this.caseData,
        planType: this.planType,
        selectedPlanData: this.selectedPlanData,
      });
    } else {
      this.commonService.showToast('Please select gateway!', 'danger');
    }
  }

  /**
   * setSelectedPlanData - It will poulate Arrangement Schedule and update the slectedPlanData Text.
   */
  setSelectedPlanData(){
    let paymentData = this.caseService.getPaymentData();
    // Extract the date in YYYY-MM-DD format
    const dateOnly = new Date(paymentData.date).toISOString().split('T')[0];

    let data = {
      'first_date': dateOnly,
      'amount': paymentData.amount,
      'ref_amount': paymentData.total,
      'mode': 'preview',
      'freq': paymentData.freq,
      'start': dateOnly,
      'method': 1
    }
    this.caseService
      .previewArrangementPlan(this.caseData.id, data)
      .subscribe((res: any) => {
        if(!res.success) {
          this.commonService.showToast(res.message, 'danger');
          this.router.navigate(['home']);
        } else {
          if (res.schedule !== "undefined") {
            let text = '';
            let scheduleData = Object.values(res.schedule)[0];
            if (Array.isArray(scheduleData)) {
              text = '<table class="col-sm-6 col-md-3" border="1" style="margin: 0 auto;">'+
                '<tr><td class="text-center"><strong class="font-weight-bold">Date Received</strong></td>'+
                '<td class="text-center"><strong class="font-weight-bold">Amount</strong></td></tr>';
              this.selectedPlanData = scheduleData.forEach((item) => {
                text += '<tr><td class="text-center">'+ moment(item['date']).format('DD-MM-YYYY') + '</td><td class="text-center">' + this.caseData.currency_symbol + item['amount'] + '</td></tr>';
              });
              text += '</table>';
              this.selectedPlanData = text;
            } else {
              console.error('res.schedule is not an array:', res.schedule);
            }
          } else {
            this.commonService.showToast(res['message'], 'danger');
          }
        }
    });
  }
}
