import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AccountDetailsPageRoutingModule } from './account-details-rountng.module';

import { AccountDetailsPage } from './account-details.page';
import { MasterHeaderModule } from '../master-header/master-header.module';
import { MasterFooterModule } from '../master-footer/master-footer.module';
import { PaymentPlanOptionComponent } from './payment-plan-option/payment-plan-option.component';
import { CommunicationPreferencesComponent } from './communication-preferences/communication-preferences.component';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AccountDetailsPageRoutingModule,
    MasterHeaderModule,
    MasterFooterModule,
    ReactiveFormsModule
  ],
  declarations: [AccountDetailsPage, PaymentPlanOptionComponent, CommunicationPreferencesComponent, NotificationPopupComponent],
})
export class AccountDetailsPageModule {}
